module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"devel.tech","short_name":"devel.tech","description":"Informative articles on python, tmux, typescript, AWS and more","start_url":"/","background_color":"#fff","theme_color":"#663399","display":"standalone","icon":"static/img/logo-white-rounded.svg","cache_busting_mode":"none","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/develtech/develtech/node_modules/gatsby-remark-prismjs","id":"b04498d2-0aaf-5ce5-be65-14bec212441a","name":"gatsby-remark-prismjs","version":"6.25.0","modulePath":"/home/runner/work/develtech/develtech/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"noInlineHighlight":false},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/develtech/develtech/www","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-96619552-1"],"gtagConfig":{"cookieExpires":0},"pluginConfig":{"exclude":["//offline-plugin-app-shell-fallback/**"],"defer":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-96619552-1","metrics":["FID","TTFB","LCP","CLS","FCP"],"eventCategory":"Performance","includeInDevelopment":false,"debug":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
