import React from "react";

import { graphql, Link, useStaticQuery, type PageProps } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

import { LayoutProvider, Paper, SEO } from "@parataxic/shared-ui";

import { TopBarContext } from "../components/Layout/constants";
import { MenuItem, TopBar } from "../components/Layout/TopBar";

import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-sans/400-italic.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/merriweather";
import "@parataxic/shared-ui/src/styles/Layout.css";
import "www/src/styles/global.css";

export const Layout: React.FC<
  PageProps & { mainProps?: React.HTMLProps<HTMLDivElement> }
> = ({ children, mainProps }) => {
  const { site, logo, ogImage }: GatsbyTypes.LayoutQuery =
    useStaticQuery<GatsbyTypes.LayoutQuery>(graphql`
      query Layout {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        logo: file(name: { eq: "logo" }, ext: { eq: ".png" }) {
          childImageSharp {
            gatsbyImageData(width: 40, placeholder: NONE, layout: FIXED)
          }
        }
        ogImage: file(name: { eq: "logo-white-rounded" }, ext: { eq: ".png" }) {
          childImageSharp {
            gatsbyImageData(width: 512, placeholder: NONE, layout: FIXED)
          }
        }
      }
    `);

  const [content, setContent] = React.useState<string | null>();
  return (
    <LayoutProvider siteMetadata={site.siteMetadata}>
      <TopBarContext.Provider value={{ setContent, content }}>
        <TopBar siteTitle={site?.siteMetadata?.title} logo={logo} />
        <SEO site={site} imageData={ogImage} />
        <Paper
          as="main"
          className="content-paper"
          // margin="0 auto"
          // maxWidth="1280px"
          // padding={{ base: 0, sm: "1rem" }}
          // height="calc(100% - 50px)"
          // {...mainProps}
        >
          {children}
        </Paper>
        <div style={{ textAlign: "center" }} className="footer">
          <div className="footer-links">
            <MenuItem className="footer-link">
              <Link to="/site/about/">About</Link>
            </MenuItem>
            <MenuItem className="footer-link">
              <Link to="/site/open-source/">Open Source</Link>
            </MenuItem>
            <MenuItem className="footer-link">
              <Link to="/site/privacy/">Privacy</Link>
            </MenuItem>
            <MenuItem className="footer-link">
              <Link to="/site/terms/">Terms</Link>
            </MenuItem>
            <MenuItem className="footer-link">
              <Link to="/site/updates/">Site Updates</Link>
            </MenuItem>
          </div>
        </div>
      </TopBarContext.Provider>
    </LayoutProvider>
  );
};

export default Layout;
