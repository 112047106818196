import React from "react";

import { Link } from "gatsby";
import {
  getImage,
  GatsbyImage as Img,
  type IGatsbyImageData,
} from "gatsby-plugin-image";

import { TopBarContext } from "./constants";

import "@parataxic/shared-ui/src/styles/TopBar.css";

interface IProps {
  logo?: IGatsbyImageData;
}

export const MenuItem: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <div className="menu-item" {...props}>
    {children}
  </div>
);

export const TopBar: React.FC<IProps> = ({ logo }) => {
  const { content } = React.useContext(TopBarContext);
  return (
    <header className="top-bar" role="navigation">
      <div className="top-bar-inner">
        {content ? (
          content
        ) : (
          <>
            <div className="logo-item">
              <Link to="/" className="logo-item--link">
                {logo && <Img image={getImage(logo)} alt="devel.tech" />}
              </Link>
            </div>
            <MenuItem>
              <Link to="/">Home</Link>
            </MenuItem>
          </>
        )}
      </div>
    </header>
  );
};

export default TopBar;
